import { all, put, takeEvery, fork, select } from 'redux-saga/effects';
import actions from './actions';
import { get, post, postWithToken, PUT } from '@iso/lib/helpers/resApiRequestor';
import * as _ from 'lodash';
// import { get, post, PUT } from '@iso/lib/helpers/resApiRequestor';


export function* fetchAuthManagementRole() {
  yield takeEvery('FETCH_AUTH_MANAGEMENT_ROLE', function* () {
    try {

      yield put({
        type: actions.FETCHING_AUTH_MANAGEMENT_ROLE,
      });

      const apiResult = yield fetchAuthManagementRoleRequest();
      const result = apiResult.data;
      // console.log('FETCH_AUTH_MANAGEMENT-RESULT: ', result);

      if (result.success === true) {
        yield put({
          type: actions.AUTH_MANAGEMENT_ROLE_FETCHED,
          payload: result.data,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_AUTH_MANAGEMENT_ROLE_FAILED,
        payload: error,
      });
    }
  });
}
// 
export function* fetchRolePermission() {
  yield takeEvery('FETCH_ROLE_PERMISSION', function* (payload) {
    try {

      yield put({
        type: actions.FETCHING_ROLE_PERMISSION,
      });

      const apiResult = yield fetchRolePermissionRequest(payload.payload);
      const result = apiResult.data;
      // console.log('FETCH_PERMISSION_ROLE-RESULT: ', result);
      const apiResultCore = yield fetchCoreModuleRequest(payload.payload);
      const resultCore = apiResultCore.data;
      // console.log('FETCH_CORE_MODULE-RESULT: ', resultCore);

      if (result.success === true) {
        yield put({
          type: actions.ROLE_PERMISSION_FETCHED,
          payload: result.data,
          payloadCore: resultCore.data,
          selectedId: payload.payload,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_ROLE_PERMISSION_FAILED,
        payload: error,
      });
    }
  });
}

export function* saveAuthManagement() {
  yield takeEvery('SAVE_AUTH_MANAGEMENT', function* (payload) {
    const data = payload.payload;
    try {

      yield put({
        type: actions.SAVING_AUTH_MANAGEMENT,
      });

      const apiResult = yield saveAuthManagementReq(data.selectedId, data);
      const result = apiResult.data;
      // console.log('FETCH_PERMISSION_ROLE-RESULT: ', result);

      if (result.success === true) {
        yield put({
          type: actions.AUTH_MANAGEMENT_SAVED,
          payload: result.data,
        });
      }
    } catch (error) {
      yield put({
        type: actions.SAVE_AUTH_MANAGEMENT_FAILED,
        payload: error,
      });
    }
  });
}
export function* addAuthRole() {
  yield takeEvery('ADD_AUTH_ROLE', function* (payload) {
    try {

      yield put({
        type: actions.ADDING_AUTH_ROLE,
      });

      const apiResult = yield addAuthRoleReq(payload.payload);
      const result = apiResult.data;
      const apiResultRole = yield fetchAuthManagementRoleRequest();
      const resultRole = apiResultRole.data;
      // console.log('ADD_AUTH_ROLE-RESULT: ', result);

      if (result.success === true) {
        yield put({
          type: actions.AUTH_ROLE_ADDED,
          payload: result.data,
        });
        yield put({
          type: actions.FETCHING_AUTH_MANAGEMENT_ROLE,
        });
      }
      if (resultRole.success === true) {
        yield put({
          type: actions.AUTH_MANAGEMENT_ROLE_FETCHED,
          payload: resultRole.data,
        });
      }
    } catch (error) {
      yield put({
        type: actions.ADD_AUTH_ROLE_FAILED,
        payload: error,
      });
    }
  });
}
export function* fetchStatus() {
  yield takeEvery('FETCH_STATUS', function* () {
    try {

      yield put({
        type: actions.FETCHING_STATUS,
      });

      const apiResult = yield fetchStatusRequest();
      const result = apiResult.data;
      // console.log('FETCH_STATUS-RESULT: ', result);

      if (result.success === true) {
        yield put({
          type: actions.STATUS_FETCHED,
          payload: result.data,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_STATUS_FAILED,
        payload: error,
      });
    }
  });
}

export function* fetchMerchantUsers() {
  yield takeEvery('FETCH_SETTINGS_MERCHANT_USERS', function* (payload) {
    // console.log('BEFORE REQUEST', payload);
    const state = yield select();
    const merchantIdentity = state.Auth.identity;
    const store = state.Auth.stores;
    const loadmore = payload.payload.loadmore;
    if (loadmore) {
      yield put({
        type: actions.LOADING_MORE_SETTINGS_MERCHANT_USERS,
      });
    } else {
      yield put({
        type: actions.FETCHING_SETTINGS_MERCHANT_USERS,
      });
    }
    try {
      const apiResult = yield fetchMerchantUsersRequest(merchantIdentity, store, payload.payload);
      // console.log();
      const result = apiResult.data;
      // console.log('FETCH M USERS', result);
      const currentList = payload.payload.currentUserList;
      const finalData = currentList ? currentList.concat(result.data.result) : result.data.result;
      yield put({
        type: actions.SETTINGS_MERCHANT_USERS_FETCHED,
        merchantUsersData: finalData,
        pagination: result.data.pagination
      });
      if (!loadmore) {
        yield put({
          type: actions.SET_SETTINGS_MERCHANT_USER_DATA,
          payload: result.data.result[0],
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_SETTINGS_MERCHANT_USERS_FAILED,
      });
    }
  });
}
// FETCH TOTAL TRANSACTIONS
export function* fetchTotalTransactions() {
  yield takeEvery('FETCH_TOTAL_TRANSACTIONS', function* (payload) {
    try {
      yield put({
        type: actions.FETCHING_TOTAL_TRANSACTIONS,
      });

      const apiResult = yield fetchTotalTransactionsData(payload.payload);
      const result = apiResult.data;
      // console.log('FETCH_TOTAL_TRANSACTIONS-RESULT: ', result);

      if (result.success === true) {
        yield put({
          type: actions.FETCHED_TOTAL_TRANSACTIONS,
          payload: result.data,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_TOTAL_TRANSACTIONS_FAILED,
        payload: error,
      });
    }
  });
}

// FETCH TOTAL TRANSACTIONS
export function* fetchAllTransactions() {
  yield takeEvery('FETCH_ALL_TRANSACTIONS', function* (payload) {
    try {
      yield put({
        type: actions.FETCHING_ALL_TRANSACTIONS,
      });

      const apiResult = yield fetchAllTransactionsData(payload.payload);
      const result = apiResult.data;
      // console.log('FETCH_ALL_TRANSACTIONS-RESULT: ', result);

      if (result.success === true) {
        yield put({
          type: actions.FETCHED_ALL_TRANSACTIONS,
          payload: result.data,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_ALL_TRANSACTIONS_FAILED,
        payload: error,
      });
    }
  });
}
// FETCH WITHHELD TRANSACTIONS
export function* fetchWithheldTransactions() {
  yield takeEvery('FETCH_WITHHELD_TRANSACTIONS', function* (payload) {
    try {
      // console.log("CHECK_FETCH_WITHHELD_TRANSACTIONS_PAYLOAD", payload);
      yield put({
        type: actions.FETCHING_WITHHELD_TRANSACTIONS,
      });
      const apiResult = yield fetchWithheldTransactionsData(payload.payload);
      const result = apiResult.data;
      // console.log('FETCH_WITHHELD_TRANSACTIONS-RESULT:', apiResult.data);
      if (result.success === true) {
        yield put({
          type: actions.FETCHED_WITHHELD_TRANSACTIONS,
          payload: result.data,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_WITHHELD_TRANSACTIONS,
        payload: error,
      });
    }
  })
}
// SEARCH WITHHELD DATE RANGE
export function* searchWithheldTransactions() {
  yield takeEvery('SEARCH_WITHHELD_TRANSACTIONS', function* (payload) {
    try {
      // console.log("CHECK_FETCH_WITHHELD_TRANSACTIONS_PAYLOAD", payload);
      yield put({
        type: actions.SEARCHING_WITHHELD_TRANSACTIONS,
      });
      const apiResult = yield searchWithheldTransactionsData(payload.payload);
      const result = apiResult.data;
      console.log('SEARCH_WITHHELD_TRANSACTIONS-RESULT:', result);
      if (result.success === true) {
        yield put({
          type: actions.SEARCHED_WITHHELD_TRANSACTIONS,
          payload: result.data,
        });
      }
    } catch (error) {
      yield put({
        type: actions.SEARCH_WITHHELD_TRANSACTIONS_FAILED,
        payload: error,
      });
    }
  })
}
// FETCH WALLET DETAILS
export function* fetchWalletDetails() {
  yield takeEvery('FETCH_CORP_WALLETS_DETAILS', function* (payload) {
    try {
      // console.log("FETCH_CORP_WALLETS_DETAILS_PAYLOAD", payload);
      yield put({
        type: actions.FETCHING_CORP_WALLETS_DETAILS,
      });
      const apiResult = yield fetchWalletDetailsData(payload.payload);
      const result = apiResult.data;
      console.log('FETCH_CORP_WALLETS_DETAILS-RESULT:', apiResult.data);
      if (result.success) {
        for (let index = 0; index < result.data.corporateWalletInfo.length; index++) {
          if (result.data.corporateWalletInfo[index].statusInfo.label === 'Deleted') {
            result.data.corporateWalletInfo.splice(index,1);
            console.log('DeletedWallet', result.data.corporateWalletInfo[index])
          }
        }
        yield put({
          type: actions.FETCHED_CORP_WALLETS_DETAILS,
          payload: result.data,
        });
      } else {
        yield put({
          type: actions.FETCH_CORP_WALLETS_DETAILS_FAILED,
        });
      }
      console.log('UPDATED-RESULT:', result.data);

      // if (result.success === true) {
      //   yield put({
      //     type: actions.FETCHED_CORP_WALLETS_DETAILS,
      //     payload: result.data,
      //   });
      // }
    } catch (error) {
      yield put({
        type: actions.FETCH_CORP_WALLETS_DETAILS_FAILED,
        payload: error,
      });
    }
  })
}

// FETCH ALL MERCHANTS
export function* fetchMerchants() {
  yield takeEvery('FETCH_MERCHANTS', function* (payload) {
    try {
      // console.log("CHECK_MERCHANTS_PAYLOAD", payload);
      yield put({
        type: actions.FETCHING_MERCHANTS,
      });
      const apiResult = yield fetchMerchantsData(payload.payload);
      const result = apiResult.data;
      // console.log('FETCH_MERCHANTS-RESULT:', apiResult.data);
      if (result.success === true) {
        yield put({
          type: actions.FETCHED_MERCHANTS,
          payload: result.data,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_MERCHANTS,
        payload: error,
      });
    }
  })
}

// FETCH CORP WALLETS
export function* fetchCorpWalletsByMid() {
  yield takeEvery('FETCH_CORP_WALLETS_BY_MID', function* (payload) {
    try {
      yield put({
        type: actions.FETCHING_CORP_WALLETS_BY_MID,
      });

      const apiResult = yield fetchCorpWalletsByMidData(payload.payload);
      const result = apiResult.data;
      // console.log('FETCH_CORP_WALLETS_BY_MID-RESULT: ', result);

      if (result.success === true) {
        yield put({
          type: actions.FETCHED_CORP_WALLETS_BY_MID,
          payload: result.data,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_CORP_WALLETS_BY_MID_FAILED,
        payload: error,
      });
    }
  });
}

// FETCH TRANSACTIONS COUNT
export function* fetchTransactionsCount() {
  yield takeEvery('FETCH_TRANSACTIONS_COUNT', function* (payload) {
    try {
      yield put({
        type: actions.FETCHING_TRANSACTIONS_COUNT,
      });
      // console.log("CHECK_PAYLOAD_TRANSACTIONS_COUNT", payload.payload);
      const apiResult = yield fetchTransactionsCountData(payload.payload);
      const result = apiResult.data;
      // console.log('FETCH_TRANSACTIONS_COUNT-RESULT: ', result.data);

      if (result.success === true) {
        yield put({
          type: actions.FETCHED_TRANSACTIONS_COUNT,
          payload: result.data,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_TRANSACTIONS_COUNT_FAILED,
        payload: error,
      });
    }
  });
}

// FETCH ALL TRANSACTIONS COUNT
export function* fetchAllTransactionsCount() {
  yield takeEvery('FETCH_ALL_TRANSACTIONS_COUNT', function* (payload) {
    try {
      yield put({
        type: actions.FETCHING_ALL_TRANSACTIONS_COUNT,
      });
      // console.log("CHECK_PAYLOAD_ALL_TRANSACTIONS_COUNT", payload.payload);
      const apiResult = yield fetchAllTransactionsCountData(payload.payload);
      const result = apiResult.data;
      console.log('FETCH_ALL_TRANSACTIONS_COUNT-RESULT: ', result);

      if (result.success === true) {
        yield put({
          type: actions.FETCHED_ALL_TRANSACTIONS_COUNT,
          payload: result.data,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_ALL_TRANSACTIONS_COUNT_FAILED,
        payload: error,
      });
    }
  });
}

// FETCH ALL TRANSACTION DETAILS
export function* fetchAllTransactionDetails() {
  yield takeEvery('FETCH_ALL_TRANSACTION_DETAILS', function* (payload) {
    // console.log("FETCHING_ALLT_TRANSACTIONS....");
    try {

      // console.log("TRANSACTION_DETAILS_PAYLOAD", payload.payload);
      if (payload.payload.loadmore) {
        yield put({
          type: actions.FETCHING_MORE_TRANSACTION_DETAILS,
        });
      } else {
        yield put({
          type: actions.FETCHING_ALL_TRANSACTION_DETAILS,
        });
      }

      // console.log("CHECK_PAYLOAD_ALL_TRANSACTION_DETAILS", payload.payload);
      const apiResult = yield fetchAllTransactionDetailsData(payload.payload);
      const result = apiResult.data;
      // console.log("RESULT_TRANX_DETAILS", result.data);
      const currentTranxDetails = payload.payload.currentTranxDetails;
      const finalTranxDetails = currentTranxDetails ? currentTranxDetails.concat(result.data.result) : result.data.result;
      // console.log('FETCH_ALL_TRANSACTION_DETAILS-RESULT: ', result.data, finalTranxDetails);

      if (result.success === true) {
        yield put({
          type: actions.FETCHED_ALL_TRANSACTION_DETAILS,
          pagination: result.data.pagination,
          payload: finalTranxDetails,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_ALL_TRANSACTION_DETAILS_FAILED,
        payload: error,
      });
    }
  });
}

// FETCH CORP WALLETS
export function* fetchBankDetailsByTranxId() {
  yield takeEvery('FETCH_BANK_DETAILS_BY_TRANSACTION_ID', function* (payload) {
    // console.log("CHECK_PAYLOAD", payload);
    try {
      yield put({
        type: actions.FETCHING_BANK_DETAILS_BY_TRANSACTION_ID,
      });

      const apiResult = yield fetchBankDetailsByTranxIdData(payload.payload);
      const result = apiResult.data;
      // console.log('FETCH_BANK_DETAILS_BY_TRANSACTION_ID-RESULT: ', result);

      if (result.success === true) {
        yield put({
          type: actions.FETCHED_BANK_DETAILS_BY_TRANSACTION_ID,
          payload: result.data,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_BANK_DETAILS_BY_TRANSACTION_ID_FAILED,
        payload: error,
      });
    }
  });
}
//UPDATE THRESHOLD
export function* updateThreshold() {
  yield takeEvery('UPDATE_THRESHOLD_AMOUNT', function* (payload) {
    try {
      yield put({
        type: actions.UPDATING_THRESHOLD_AMOUNT,
      });
      const apiResult = yield updateThresholdData(payload.payload);
      const result = apiResult.data;
      console.log('UPDATE_THRESHOLD_AMOUNT-RESULT: ', apiResult);
      if (result.success === true) {
        yield put({
          type: actions.UPDATED_THRESHOLD_AMOUNT,
          payload: result.data,
          message: 'Merchant details successfully updated',
        });
      }
    } catch (error) {
      yield put({
        type: actions.UPDATE_THRESHOLD_AMOUNT_FAILED,
        payload: error,
        message: 'Merchant details update failed',
      });
    }
  });
}
// FETCH WAlLET GROUPS
export function* fetchWalletGroups() {
  yield takeEvery('FETCH_WALLET_GROUPS', function* (payload) {
    try {
      yield put({
        type: actions.FETCHING_WALLET_GROUPS,
      });
      const apiResult = yield fetchWalletGroupsData(payload.payload);
      const result = apiResult.data;
      console.log('FETCH_WALLET_GROUPS-RESULT: ', apiResult);
      if (result.success === true) {
        yield put({
          type: actions.FETCHED_WALLET_GROUPS,
          payload: result.data,
          message: 'Merchant details successfully updated',
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_WALLET_GROUPS_FAILED,
        payload: error,
        message: 'Merchant details update failed',
      });
    }
  });
}
// FETCH WALLET GROUPS
export function* fetchLookupsCurrency() {
  yield takeEvery('FETCH_LOOKUPS_CURRENCY', function* (payload) {
    try {
      yield put({
        type: actions.FETCHING_LOOKUPS_CURRENCY,
      });
      const apiResult = yield fetchLookupsCurrencyData(payload.payload);
      const result = apiResult.data;
      console.log('FETCH_LOOKUPS_CURRENCY-RESULT: ', apiResult);
      if (result.success === true) {
        yield put({
          type: actions.FETCHED_LOOKUPS_CURRENCY,
          payload: result.data,
          message: 'Merchant details successfully updated',
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_LOOKUPS_CURRENCY_FAILED,
        payload: error,
        message: 'Merchant details update failed',
      });
    }
  });
}
//ADD CORP WALLET
export function* addCorpWallet() {
  yield takeEvery('ADD_CORP_WALLET', function* (payload) {
    try {
      yield put({
        type: actions.ADDING_CORP_WALLET,
      });
      const apiResult = yield addCorpWalletData(payload.payload);
      const result = apiResult.data;
      console.log('ADD_CORP_WALLET-RESULT: ', apiResult);
      if (result.success === true) {
        const systemParams = yield addCorpWalletSystemParamsData(payload.payload);
        console.log('systemParams', systemParams);
        yield put({
          type: actions.ADDED_CORP_WALLET,
          payload: result.data,
          message: 'Wallet successfully Added',
        });
      }
    } catch (error) {
      yield put({
        type: actions.ADD_CORP_WALLET_FAILED,
        payload: error,
        message: 'Merchant details update failed',
      });
    }
  });
}
// POST CREDIT TRANSACTION
export function* postCreditTransaction() {
  yield takeEvery('POST_CREDIT_TRANSACTION', function* (payload) {
    try {
      yield put({
        type: actions.POSTING_CREDIT_TRANSACTION,
      });
      const apiResult = yield postCreditTransactionData(payload.payload);
      const result = apiResult.data;
      console.log('POST_CREDIT_TRANSACTION-RESULT: ', apiResult);
      if (result.success === true || apiResult.statusText === "OK") {
        yield put({
          type: actions.POSTED_CREDIT_TRANSACTION,
          payload: result,
          message: 'Credit Transaction Success',
        });
      } else {
        yield put({
          type: actions.POST_CREDIT_TRANSACTION_FAILED,
          payload: result,
          message: result.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.POST_CREDIT_TRANSACTION_FAILED,
        payload: error,
        message: 'Credit Transaction Failed',
      });
    }
  });
}

// POST DEDIT TRANSACTION
export function* postDebitTransaction() {
  yield takeEvery('POST_DEBIT_TRANSACTION', function* (payload) {
    try {
      yield put({
        type: actions.POSTING_DEBIT_TRANSACTION,
      });
      const apiResult = yield postDebitTransactionData(payload.payload);
      const result = apiResult.data;
      console.log('POST_DEBIT_TRANSACTION-RESULT: ', apiResult);
      console.log('result.success', result.success);
      if (result.success === true || apiResult.statusText === 'OK') {
        yield put({
          type: actions.POSTED_DEBIT_TRANSACTION,
          payload: result,
          message: 'Debit Transaction Success',
        });
      }
      else {
        yield put({
          type: actions.POST_DEBIT_TRANSACTION_FAILED,
          payload: result.statusText,
          message: result.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.POST_DEBIT_TRANSACTION_FAILED,
        payload: error,
        message: 'Debit Transaction Failed',
      });
    }
  });
}
//POST TRANSFER TRANSACTION
export function* postTransferTransaction() {
  yield takeEvery('POST_TRANSFER_TRANSACTION', function* (payload) {
    try {
      yield put({
        type: actions.POSTING_TRANSFER_TRANSACTION,
      });
      const apiResult = yield postTransferTransactionData(payload.payload);
      const result = apiResult.data;
      console.log('POST_TRANSFER_TRANSACTION-RESULT: ', apiResult);
      if (result.success === true || result.statusText === "OK") {
        yield put({
          type: actions.POSTED_TRANSFER_TRANSACTION,
          payload: result,
          message: 'Transfer Transaction Success',
        });
      } else {
        yield put({
          type: actions.POST_TRANSFER_TRANSACTION_FAILED,
          payload: result,
          message: result.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.POST_TRANSFER_TRANSACTION_FAILED,
        payload: error,
        message: 'Transfer Transaction Failed',
      });
    }
  });
}
//POST SETTLE TRANSACTION
export function* postSettleTransaction() {
  yield takeEvery('POST_SETTLE_TRANSACTION', function* (payload) {
    try {
      yield put({
        type: actions.POSTING_SETTLE_TRANSACTION,
      });
      const apiResult = yield postSettleTransactionData(payload.payload);
      const result = apiResult.data;
      console.log('POST_SETTLE_TRANSACTION-RESULT: ', apiResult);
      if (result.success === true) {
        yield put({
          type: actions.POSTED_SETTLE_TRANSACTION,
          payload: result.data,
          message: 'Transaction Settled',
        });
      } else {
        yield put({
          type: actions.POST_SETTLE_TRANSACTION_FAILED,
          payload: result.data,
          message: 'Failed to Settle Transaction',
        });
      }
    } catch (error) {
      yield put({
        type: actions.POST_SETTLE_TRANSACTION_FAILED,
        payload: error,
        message: 'Failed to Settle Transaction',
      });
    }
  });
}
//POST CANCEL TRANSACTION
export function* postCancelTransaction() {
  yield takeEvery('POST_CANCEL_TRANSACTION', function* (payload) {
    try {
      yield put({
        type: actions.POSTING_CANCEL_TRANSACTION,
      });
      const apiResult = yield postCancelTransactionData(payload.payload);
      const result = apiResult.data;
      console.log('POST_CANCEL_TRANSACTION-RESULT: ', apiResult);
      if (result.success === true) {
        yield put({
          type: actions.POSTED_CANCEL_TRANSACTION,
          payload: result,
          message: 'Transaction Canceled',
        });
      } else {
        yield put({
          type: actions.POST_CANCEL_TRANSACTION_FAILED,
          payload: result.data,
          message: 'Failed to Cancel Transaction',
        });
      }
    } catch (error) {
      yield put({
        type: actions.POST_CANCEL_TRANSACTION_FAILED,
        payload: error,
        message: 'Failed to Cancel Transaction',
      });
    }
  });
}
// FETCH DAILY BALANCE
export function* fetchDailyBalance() {
  yield takeEvery('FETCH_DAILY_BALANCE_DETAILS', function* (payload) {
    try {
      yield put({
        type: actions.FETCHING_DAILY_BALANCE_DETAILS,
      });
      const apiResult = yield fetchDailyBalanceData(payload.payload);
      const result = apiResult.data;
      console.log('FETCH_DAILY_BALANCE_DETAILS-RESULT: ', apiResult);
      if (result.success === true) {
        yield put({
          type: actions.FETCHED_DAILY_BALANCE_DETAILS,
          payload: result.data,
          message: 'Merchant details successfully updated',
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_DAILY_BALANCE_DETAILS_FAILED,
        payload: error,
        message: 'Merchant details update failed',
      });
    }
  });
}
// TRX QUEUE
export function* fetchTrxQueueMerchants() {
  yield takeEvery('FETCH_TRX_QUEUE_MERCHANT', function* (payload) {
    console.log('trxQueueMerchantPayload', payload.payload);
    const loadmore = payload.payload.loadmore;
    if (loadmore) {
      yield put({
        type: actions.LOADING_MORE_TRX_QUEUE_MERCHANT,
      });
    } else {
      yield put({
        type: actions.FETCHING_TRX_QUEUE_MERCHANT,
      });
    }
    try {
      const apiResult = yield getTrxQueueMerchant(payload.payload);
      const result = apiResult.data;
      console.log('FETCH_TRX_QUEUE_MERCHANT-RESULT: ', result);
      const currentList = payload.payload.currentList;
      const finalData = currentList ? currentList.concat(result.data.merchant) : result.data.merchant;
      // yield put({
      //   type: actions.FETCHED_MID_GROUPS,
      //   midGroups: finalData,
      //   pagination: result.data.pagination
      // });
      if (result.success === true) {
        yield put({
          type: actions.FETCHED_TRX_QUEUE_MERCHANT,
          payload: finalData,
          limit: result.data.limit,
          offset: result.data.offset,
          maxLength: result.data.rowLength,
          // pagination: result.data.pagination
        });
      } else {
        yield put({
          type: actions.FETCH_TRX_QUEUE_MERCHANT_FAILED,
          payload: result.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_TRX_QUEUE_MERCHANT_FAILED,
        payload: error,
      });
    }
  });
}
export function* fetchTrxQueue() {
  yield takeEvery('FETCH_TRX_QUEUE', function* (payload) {
    yield put({
      type: actions.FETCHING_TRX_QUEUE,
    });
    try {
      const apiResult = yield getTrxQueues(payload.payload);
      const result = apiResult.data;
      console.log('FETCH_TRX_QUEUE-RESULT: ', result);
      if (result.success === true) {
        yield put({
          type: actions.FETCHED_TRX_QUEUE,
          payload: result.data,
        });
      } else {
        yield put({
          type: actions.FETCH_TRX_QUEUE_FAILED,
          payload: result.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_TRX_QUEUE_FAILED,
        payload: error,
      });
    }
  });
}
export function* fetchTrxQueueWalletId() {
  yield takeEvery('FETCH_TRX_QUEUE_WALLET_ID', function* (payload) {
    yield put({
      type: actions.FETCHING_TRX_QUEUE_WALLET_ID,
    });
    try {
      const apiResult = yield getTrxQueueWalletIds(payload.payload);
      const result = apiResult.data;
      console.log('FETCH_TRX_QUEUE_WALLET_ID-RESULT: ', result);
      if (result.success === true) {
        yield put({
          type: actions.FETCHED_TRX_QUEUE_WALLET_ID,
          payload: result.data.wallet,
        });
      } else {
        yield put({
          type: actions.FETCH_TRX_QUEUE_WALLET_ID_FAILED,
          payload: result.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_TRX_QUEUE_WALLET_ID_FAILED,
        payload: error,
      });
    }
  });
}
export function* trxQueueFilter() {
  yield takeEvery('FILTER_TRX_QUEUE', function* (payload) {
    yield put({
      type: actions.FILTERING_TRX_QUEUE,
    });
    try {
      const apiResult = yield filteringTrxQueue(payload.payload);
      const result = apiResult.data;
      console.log('FILTER_TRX_QUEUE-RESULT: ', result);
      if (result.success === true) {
        yield put({
          type: actions.FILTERED_TRX_QUEUE,
          trxQueueFilterData: result.data.queueTransactionFilter,
        });
      } else {
        yield put({
          type: actions.FILTER_TRX_QUEUE_FAILED,
          payload: result.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FILTER_TRX_QUEUE_FAILED,
        payload: error,
      });
    }
  });
}

// FETCH SEARCH TRANSACTION DETAILS
export function* fetchSearchTransactionDetails() {
  yield takeEvery('FETCH_SEARCH_TRANSACTION_DETAILS', function* (payload) {
    // console.log("FETCHING_SEARCH_TRANSACTIONS....");
    try {

      // console.log("TRANSACTION_SEARCH_DETAILS_PAYLOAD", payload.payload);
      if (payload.payload.loadmore) {
        yield put({
          type: actions.FETCHING_MORE_SEARCHED_TRANSACTION_DETAILS,
        });
      } else {
        yield put({
          type: actions.FETCHING_SEARCH_TRANSACTION_DETAILS,
        });
      }

      // console.log("CHECK_PAYLOAD_ALL_TRANSACTION_DETAILS", payload.payload);
      const apiResult = yield fetchSearchTransactionDetailsData(payload.payload);
      const result = apiResult.data;
      // console.log("RESULT_TRANX_SEARCH_DETAILS", result.data);
      const currentTranxSearchDetails = payload.payload.currentTranxSearchDetails;
      const finalTranxSearchDetails = currentTranxSearchDetails ? currentTranxSearchDetails.concat(result.data) : result.data;
      // console.log('FETCH_SEARCH_TRANSACTION_DETAILS-RESULT: ', result.data, finalTranxSearchDetails);

      if (result.success === true) {
        yield put({
          type: actions.FETCHED_SEARCH_TRANSACTION_DETAILS,
          pagination: result.data.pagination,
          payload: finalTranxSearchDetails,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_SEARCH_TRANSACTION_DETAILS_FAILED,
      });
    }
  });
}
// DOWNLOAD TRANSACTION REPORT
export function* downloadWithdrawLoadReport() {
  yield takeEvery('DOWNLOAD_LOAD_WITHDRAW_REPORT', function* (payload) {
    yield put({
      type: actions.DOWNLOADING_LOAD_WITHDRAW_REPORT,
    });
    try {
      const state = yield select();
      const apiResult = yield downloadTrxReport(payload.payload);
      const result = apiResult.data;
      console.log('DOWNLOAD_LOAD_WITHDRAW_REPORT-RESULT: ', result);
      if (result.success === true && result.count > 0) {
        yield put({
          type: actions.DOWNLOADED_LOAD_WITHDRAW_REPORT,
          payload: result.data,
        });
        const currentData = state.CorporateAccount.downloadList;
        const newList = _.union([result.data], currentData);
        // console.log('CURRENT_DATA', currentData);
        yield put({
          type: actions.FETCHED_DOWNLOAD_LIST,
          payload: newList,
        });
      } else {
        yield put({
          type: actions.DOWNLOAD_LOAD_WITHDRAW_FAILED,
          payload: result.message ? result.message : 'No Transaction Found!',
        });
      }
    } catch (error) {
      yield put({
        type: actions.DOWNLOAD_LOAD_WITHDRAW_FAILED,
        payload: 'Error in generating transaction file!',
      });
    }
  });
}
// DOWNLOAD ADMIN REPORT
export function* downloadAdminReport() {
  yield takeEvery('DOWNLOAD_ADMIN_REPORT', function* (payload) {
    yield put({
      type: actions.DOWNLOADING_ADMIN_REPORT,
    });
    try {
      const state = yield select();

      let apiResult;
      if (payload.payload.trxType !== 'WITHHELD') {
        apiResult = yield downloadingAdminReport(payload.payload);
      } else {
        apiResult = yield downloadingAdminWithheldReport(payload.payload);
      }
      const result = apiResult.data;
      console.log('DOWNLOAD_ADMIN_REPORT-RESULT: ', result);
      if (result.success === true && result.count > 0) {
        yield put({
          type: actions.DOWNLOADED_ADMIN_REPORT,
          payload: result.data,
        });
        const currentData = state.CorporateAccount.downloadList;
        const newList = _.union([result.data], currentData);
        // console.log('CURRENT_DATA', currentData);
        yield put({
          type: actions.FETCHED_DOWNLOAD_LIST,
          payload: newList,
        });
      } else {
        yield put({
          type: actions.DOWNLOAD_ADMIN_REPORT_FAILED,
          payload: result.message ? result.message : 'No Transaction found!',
        });
      }
    } catch (error) {
      yield put({
        type: actions.DOWNLOAD_ADMIN_REPORT_FAILED,
        payload: 'Error in generating transaction file!',
      });
    }
  });
}
export function* downloadReconciliationReport() {
  yield takeEvery('DOWNLOAD_RECONCILIATION_REPORT', function* (payload) {
    yield put({
      type: actions.DOWNLOADING_RECONCILIATION_REPORT,
    });
    try {
      const state = yield select();
      const apiResult = yield downloadReconReport(payload.payload);
      const result = apiResult.data;
      console.log('DOWNLOAD_RECONCILIATION_REPORT-RESULT: ', result);
      if (result.success === true && result.count > 0) {
        yield put({
          type: actions.DOWNLOADED_RECONCILIATION_REPORT,
          payload: result.data,
        });
        const currentData = state.CorporateAccount.downloadList;
        const newList = _.union([result.data], currentData);
        // console.log('CURRENT_DATA', currentData);
        yield put({
          type: actions.FETCHED_DOWNLOAD_LIST,
          payload: newList,
        });
      } else {
        yield put({
          type: actions.DOWNLOAD_RECONCILIATION_REPORT_FAILED,
          payload: result.message ? result.message : 'No Transaction found!',
        });
      }
    } catch (error) {
      yield put({
        type: actions.DOWNLOAD_RECONCILIATION_REPORT_FAILED,
        payload: 'Error in generating transaction file!',
      });
    }
  });
}
export function* fetchDownloadList() {
  yield takeEvery('FETCH_DOWNLOAD_LIST', function* (payload) {
    yield put({
      type: actions.FETCHING_DOWNLOAD_LIST,
    });
    try {
      const apiResult = yield getDownloadLists(payload.payload);
      const result = apiResult.data;
      console.log('FETCH_DOWNLOAD_LIST-RESULT: ', result);
      if (result.success === true) {
        yield put({
          type: actions.FETCHED_DOWNLOAD_LIST,
          payload: result.data,
        });
      } else {
        yield put({
          type: actions.FETCH_DOWNLOAD_LIST_FAILED,
          payload: result.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_DOWNLOAD_LIST_FAILED,
        payload: error,
      });
    }
  });
}
export function* fetchPrevDownloads() {
  yield takeEvery('FETCH_PREVIOUS_DOWNLOADS', function* (payload) {
    yield put({
      type: actions.FETCHING_PREVIOUS_DOWNLOADS,
    });
    try {
      const apiResult = yield getPrevDownloads(payload.payload);
      const result = apiResult.data;
      // console.log('FETCH_PREVIOUS_DOWNLOADS-RESULT: ', result);
      if (result.success === true) {
        yield put({
          type: actions.FETCHED_PREVIOUS_DOWNLOADS,
          payload: result.data,
        });
      } else {
        yield put({
          type: actions.FETCH_PREVIOUS_DOWNLOADS_FAILED,
          payload: result.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_PREVIOUS_DOWNLOADS_FAILED,
        payload: error,
      });
    }
  });
}
export function* fetchFundSource() {
  yield takeEvery('FETCH_FUND_SOURCE_GRP', function* (payload) {
    console.log('fundSourcePayload 1', payload);
    const loadmore = payload.payload.loadmore;
    if (loadmore) {
      // console.log('fundSourcePayload 2');
      yield put({
        type: actions.LOADING_MORE_FUND_SOURCE_GRP,
      });
    } else {
      // console.log('fundSourcePayload 3');
      yield put({
        type: actions.FETCHING_FUND_SOURCE_GRP,
      });
    }
    try {
      // console.log('fundSourcePayload 4');
      const apiResult = yield getFundSourceData(payload.payload);
      const result = apiResult.data;
      console.log('FETCH_FUND_SOURCE_GRP-RESULT: ', result);
      const currentList = payload.payload.currentFundSourceList;
      const finalData = currentList ? currentList.concat(result.data.result) : result.data.result;
      console.log('fundSourcePayload 5', finalData);
      if (result.success === true) {
        console.log('fundSourcePayload 6');
        yield put({
          type: actions.FETCHED_FUND_SOURCE_GRP,
          payload: finalData,
          pagination: result.data.pagination,
        });
      } else {
        yield put({
          type: actions.FETCH_FUND_SOURCE_GRP_FAILED,
          payload: result.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_FUND_SOURCE_GRP_FAILED,
        payload: error,
      });
    }
  });
}
export function* fetchPredictiveBalance() {
  yield takeEvery('FETCH_PREDICTIVE_BALANCE_GRP', function* (payload) {
    console.log('predictiveBalancePayload 1', payload);
    const loadmore = payload.payload.loadmore;
    if (loadmore) {
      // console.log('fundSourcePayload 2');
      yield put({
        type: actions.LOADING_MORE_PREDICTIVE_BALANCE_GRP,
      });
    } else {
      // console.log('fundSourcePayload 3');
      yield put({
        type: actions.FETCHING_PREDICTIVE_BALANCE_GRP,
      });
    }
    try {
      // console.log('fundSourcePayload 4');
      const apiResult = yield getPredictiveBalanceData(payload.payload);
      const result = apiResult.data;
      console.log('FETCH_PREDICTIVE_BALANCE_GRP-RESULT: ', result);
      console.log('FETCH_PREDICTIVE_BALANCE_GRP-payload: ', payload.payload);

      const currentList = payload.payload.currentPredictiveBalanceList;
      const finalData = currentList ? currentList.concat(result.data.result) : result.data.result;
      // console.log('predictiveBalancePayload 5', finalData);
      if (result.success === true) {
        // console.log('predictiveBalancePayload 6');
        yield put({
          type: actions.FETCHED_PREDICTIVE_BALANCE_GRP,
          payload: finalData,
          pagination: result.data.pagination,
        });
      } else {
        yield put({
          type: actions.FETCH_PREDICTIVE_BALANCE_GRP_FAILED,
          payload: result.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_PREDICTIVE_BALANCE_GRP_FAILED,
        payload: error,
      });
    }
  });
}
export function* fetchPredictiveBalanceBankAccount() {
  yield takeEvery('FETCH_PB_BA', function* (payload) {
    console.log('predictiveBalancePayload 1', payload);
    // console.log('fundSourcePayload 3');
    yield put({
      type: actions.FETCHING_PB_BA,
    });
    try {
      // console.log('fundSourcePayload 4');
      const apiResult = yield getPredictiveBalanceBankAccount(payload.payload);
      const result = apiResult.data;
      console.log('FETCH_PB_BA-RESULT: ', result);
      // console.log('predictiveBalancePayload 5', finalData);
      if (result.success === true) {
        // console.log('predictiveBalancePayload 6');
        yield put({
          type: actions.FETCHED_PB_BA,
          payload: result.data,
        });
      } else {
        yield put({
          type: actions.FETCH_PB_BA_FAILED,
          payload: result.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_PB_BA_FAILED,
        payload: error,
      });
    }
  });
}
export function* fetchPast90Days() {
  yield takeEvery('FETCH_PB_90DAYS', function* (payload) {
    console.log('predictiveBalancePayload 1', payload);
    yield put({
      type: actions.FETCHING_PB_90DAYS,
    });
    try {
      // console.log('fundSourcePayload 4');
      const apiResult = yield getPast90Days(payload.payload);
      const result = apiResult.data;
      console.log('FETCH_PB_90_DAYS_RESULT: ', result);
      if (result.success === true) {
        yield put({
          type: actions.FETCHED_PB_90DAYS,
          payload: result.data,
        });
      } else {
        yield put({
          type: actions.FETCH_PB_90DAYS_FAILED,
          payload: result.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_PB_90DAYS_FAILED,
        payload: error,
      });
    }
  });
}
export function* fetchBankAccts() {
  yield takeEvery('FETCH_BANK_ACCOUNTS', function* (payload) {
    yield put({
      type: actions.FETCHING_BANK_ACCOUNTS,
    });
    try {
      const apiResult = yield getBankAccts(payload.payload);
      const result = apiResult.data;
      console.log('FETCH_BANK_ACCOUNTS-RESULT: ', result);
      if (result.success === true) {
        yield put({
          type: actions.FETCHED_BANK_ACCOUNTS,
          payload: result.data,
        });
      } else {
        yield put({
          type: actions.FETCH_BANK_ACCOUNTS_FAILED,
          payload: result.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_BANK_ACCOUNTS_FAILED,
        payload: error,
      });
    }
  });
}
export function* addingFundSource() {
  yield takeEvery('ADD_FUND_SOURCE', function* (payload) {
    yield put({
      type: actions.ADDING_FUND_SOURCE,
    });
    try {
      const apiResult = yield fundSourceAdd(payload.payload);
      const result = apiResult.data;
      console.log('ADD_FUND_SOURCE-RESULT: ', result);
      if (result.success === true) {
        yield put({
          type: actions.ADDED_FUND_SOURCE,
          payload: result.data,
          result: result.success,
        });
      } else {
        yield put({
          type: actions.ADD_FUND_SOURCE_FAILED,
          payload: result.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.ADD_FUND_SOURCE_FAILED,
        payload: error,
      });
    }
  });
}
export function* editingFundSource() {
  yield takeEvery('EDIT_FUND_SOURCE', function* (payload) {
    yield put({
      type: actions.EDITING_FUND_SOURCE,
    });
    try {
      const apiResult = yield fundSourceEdit(payload.payload);
      const result = apiResult.data;
      console.log('EDIT_FUND_SOURCE-RESULT: ', result);
      if (result.success === true) {
        yield put({
          type: actions.EDITED_FUND_SOURCE,
          payload: result.data,
          result: result.success,
        });
      } else {
        yield put({
          type: actions.EDIT_FUND_SOURCE_FAILED,
          payload: result.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.EDIT_FUND_SOURCE_FAILED,
        payload: error,
      });
    }
  });
}
export function* deletingFundSource() {
  yield takeEvery('DELETE_FUND_SOURCE', function* (payload) {
    yield put({
      type: actions.DELETING_FUND_SOURCE,
    });
    try {
      const apiResult = yield fundSourceDelete(payload.payload);
      const result = apiResult.data;
      console.log('DELETE_FUND_SOURCE-RESULT: ', result);
      if (result.success === true) {
        yield put({
          type: actions.DELETED_FUND_SOURCE,
          payload: result.data,
          result: result.success,
        });
      } else {
        yield put({
          type: actions.DELETE_FUND_SOURCE_FAILED,
          payload: result.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.DELETE_FUND_SOURCE_FAILED,
        payload: error,
      });
    }
  });
}
export function* deactivatingFundSource() {
  yield takeEvery('DEACTIVATE_FUND_SOURCE', function* (payload) {
    yield put({
      type: actions.DEACTIVATING_FUND_SOURCE,
    });
    try {
      const apiResult = yield fundSourceDeactivate(payload.payload);
      const result = apiResult.data;
      console.log('DEACTIVATE_FUND_SOURCE-RESULT: ', result);
      if (result.success === true) {
        yield put({
          type: actions.DEACTIVATED_FUND_SOURCE,
          payload: result.data,
          result: result.success,
        });
      } else {
        yield put({
          type: actions.DEACTIVATE_FUND_SOURCE_FAILED,
          payload: result.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.DEACTIVATE_FUND_SOURCE_FAILED,
        payload: error,
      });
    }
  });
}

// CHANGE WALLET STATUS
export function* changeWalletStatus() {
  yield takeEvery('CHANGE_WALLET_STATUS', function* (payload) {
    try {
      yield put({
        type: actions.CHANGING_WALLET_STATUS,
      });
      const apiResult = yield changeWalletStatusData(payload.payload);
      const result = apiResult.data;
      console.log('CHANGE_WALLET_STATUS-RESULT: ', apiResult);
      if (result.success === true || apiResult.statusText === "OK") {
        yield put({
          type: actions.CHANGED_WALLET_STATUS,
          payload: result,
          message: result.data.statusInfo.label === 'Active' ? `${result.data.name} Activated Sucessfully` : `${result.data.name} Deactivated Sucessfully`,
        });
      } else {
        yield put({
          type: actions.CHANGE_WALLET_STATUS_FAILED,
          payload: apiResult.statusText,
          message: result.data.statusInfo.label === 'Active' ? `Failed to Activated ${result.data.name} ` : `Failed to Deactivated ${result.data.name}`,
        });
      }
    } catch (error) {
      yield put({
        type: actions.CHANGE_WALLET_STATUS_FAILED,
        payload: error,
        message: 'Status Change Failed',
      });
    }
  });
}

export function* fetchBankAccountByAcctNo() {
  yield takeEvery('FETCH_BANK_ACCOUNT_BY_ACCT_NO', function* (payload) {
    // console.log("PAYLOAD_BANK", payload);
    yield put({
      type: actions.FETCHING_BANK_ACCOUNT_BY_ACCT_NO,
    });
    try {
      const apiResult = yield fetchBankAccountByAcctNoData(payload.payload);
      const result = apiResult.data;
      // console.log('FETCH_BANK_ACCOUNT_BY_ACCT_NO-RESULT: ', result);
      if (result.success === true) {
        yield put({
          type: actions.FETCHED_BANK_ACCOUNT_BY_ACCT_NO,
          payload: result.data,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_BANK_ACCOUNT_BY_ACCT_NO_FAILED,
        payload: error,
      });
    }
  });
}

// CRUD BANK ACCOUNT
export function* addingBankAccount() {
  yield takeEvery('ADD_BANK_ACCOUNT', function* (payload) {
    yield put({
      type: actions.ADDING_BANK_ACCOUNT,
    });
    try {
      const apiResult = yield bankAccountAddData(payload.payload);
      const result = apiResult.data;
      console.log('ADD_BANK_ACCOUNT-RESULT: ', result);
      if (result.success === true) {
        yield put({
          type: actions.ADDED_BANK_ACCOUNT,
          payload: result.data,
          result: result.success,
        });
      } else {
        yield put({
          type: actions.ADD_BANK_ACCOUNT_FAILED,
          payload: result.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.ADD_BANK_ACCOUNT_FAILED,
        payload: error,
      });
    }
  });
}
export function* editingBankAccount() {
  yield takeEvery('EDIT_BANK_ACCOUNT', function* (payload) {
    yield put({
      type: actions.EDITING_BANK_ACCOUNT,
    });
    try {
      const apiResult = yield bankAccountEditData(payload.payload);
      const result = apiResult.data;
      console.log('EDIT_BANK_ACCOUNT-RESULT: ', result);
      if (result.success === true) {
        yield put({
          type: actions.EDITED_BANK_ACCOUNT,
          payload: result.data,
          result: result.success,
        });
      } else {
        yield put({
          type: actions.EDIT_BANK_ACCOUNT_FAILED,
          payload: result.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.EDIT_BANK_ACCOUNT_FAILED,
        payload: error,
      });
    }
  });
}
export function* deletingBankAccount() {
  yield takeEvery('DELETE_BANK_ACCOUNT', function* (payload) {
    yield put({
      type: actions.DELETING_BANK_ACCOUNT,
    });
    try {
      const apiResult = yield bankAccountDeleteData(payload.payload);
      const result = apiResult.data;
      console.log('DELETE_BANK_ACCOUNT-RESULT: ', result);
      if (result.success === true) {
        yield put({
          type: actions.DELETED_BANK_ACCOUNT,
          payload: result.data,
          result: result.success,
        });
      } else {
        yield put({
          type: actions.DELETE_BANK_ACCOUNT_FAILED,
          payload: result.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.DELETE_BANK_ACCOUNT_FAILED,
        payload: error,
      });
    }
  });
}

export function* deactivatingBankAccount() {
  yield takeEvery('DEACTIVATE_BANK_ACCOUNT', function* (payload) {
    yield put({
      type: actions.DEACTIVATING_BANK_ACCOUNT,
    });
    try {
      const apiResult = yield bankAccountDeactivateData(payload.payload);
      const result = apiResult.data;
      console.log('DEACTIVATE_BANK_ACCOUNT-RESULT: ', result);
      if (result.success === true) {
        yield put({
          type: actions.DEACTIVATED_BANK_ACCOUNT,
          payload: result.data,
          result: result.success,
        });
      } else {
        yield put({
          type: actions.DEACTIVATE_BANK_ACCOUNT_FAILED,
          payload: result.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.DEACTIVATE_BANK_ACCOUNT_FAILED,
        payload: error,
      });
    }
  });
}

// FETCH WALLET GROUPS
export function* fetchLookupsBank() {
  yield takeEvery('FETCH_LOOKUPS_BANK', function* () {
    try {
      yield put({
        type: actions.FETCHING_LOOKUPS_BANK,
      });
      const apiResult = yield fetchLookupsBankData();
      const result = apiResult.data;
      console.log('FETCH_LOOKUPS_BANK-RESULT: ', apiResult);
      if (result.success === true) {
        yield put({
          type: actions.FETCHED_LOOKUPS_BANK,
          payload: result.data.result,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_LOOKUPS_BANK_FAILED,
        payload: error,
      });
    }
  });
}

// FETCH SEARCH MERCHANT TRANSACTION DETAILS
export function* fetchSearchMerchantTransactionDetails() {
  yield takeEvery('FETCH_SEARCH_MERCHANT_TRANSACTION_DETAILS', function* (payload) {
    console.log("FETCHING_SEARCH_MERCHANT_TRANSACTIONS....");
    try {
      console.log("TRANSACTION_SEARCH_MERCHANT_DETAILS_PAYLOAD", payload.payload);
      if (payload.payload.loadmore) {
        yield put({
          type: actions.FETCHING_MORE_SEARCHED_MERCHANT_TRANSACTION_DETAILS,
        });
      } else {
        yield put({
          type: actions.FETCHING_SEARCH_MERCHANT_TRANSACTION_DETAILS,
        });
      }

      // console.log("CHECK_PAYLOAD_ALL_MERCHANT_TRANSACTION_DETAILS", payload.payload);
      const apiResult = yield fetchSearchMerchantTransactionDetailsData(payload.payload);
      const result = apiResult.data;
      console.log("RESULT_TRANX_SEARCH_DETAILS-1", result.data);
      console.log("RESULT_TRANX_SEARCH_DETAILS-2", payload);
      const currentMerchTranxSearchDetails = payload.payload.currentMerchTranxSearchDetails;
      const finalMerchTranxSearchDetails = currentMerchTranxSearchDetails ? currentMerchTranxSearchDetails.concat(result.data.result) : result.data.result;
      console.log('FETCH_SEARCH_MERCHANT_TRANSACTION_DETAILS-RESULT: ', result.data, finalMerchTranxSearchDetails);

      if (result.success === true) {
        yield put({
          type: actions.FETCHED_SEARCH_MERCHANT_TRANSACTION_DETAILS,
          pagination: result.data.pagination,
          payload: finalMerchTranxSearchDetails,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_SEARCH_MERCHANT_TRANSACTION_DETAILS_FAILED,
      });
    }
  });
}

// SEARCH WALLET DETAILS
export function* walletDetailsSearch() {
  yield takeEvery('SEARCH_WALLET_DETAILS', function* (payload) {
    yield put({
      type: actions.SEARCHING_WALLET_DETAILS,
    });
    try {
      const apiResult = yield searchingWalletDetails(payload.payload);
      const result = apiResult.data;
      console.log('SEARCH_WALLET_DETAILS-RESULT: ', result);
      if (result.success === true) {
        yield put({
          type: actions.SEARCHED_WALLET_DETAILS,
          payload: result.data,
          result: result.success,
        });
      } else {
        yield put({
          type: actions.SEARCH_WALLET_DETAILS_FAILED,
          payload: result.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.SEARCH_WALLET_DETAILS_FAILED,
        payload: error,
      });
    }
  });
}
export function* fetchIntegrationRoles() {
  yield takeEvery('FETCH_INTEGRATION_ROLE', function* () {
    yield put({
      type: actions.FETCHING_INTEGRATION_ROLE,
    });
    try {
      const apiResult = yield fetchIntegrationRolesData();
      const result = apiResult.data;
      console.log('FETCH_INTEGRATION_ROLE-RESULT: ', result);
      if (result.success === true) {
        yield put({
          type: actions.FETCHED_INTEGRATION_ROLE,
          payload: result.data,
        });
      } else {
        yield put({
          type: actions.FETCHING_INTEGRATION_ROLE_FAILED,
          payload: result.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCHING_INTEGRATION_ROLE_FAILED,
        payload: error,
      });
    }
  });
}
export function* fetchIntegrationRolesPrivileges() {
  yield takeEvery('FETCH_INTEGRATION_ROLES_PRIVILEGES', function* (payload) {
    yield put({
      type: actions.FETCHING_INTEGRATION_ROLES_PRIVILEGES,
    });
    try {
      const apiResult = yield fetchIntegrationRolesPrivilegesData(payload.payload);
      const result = apiResult.data;
      console.log('FETCH_INTEGRATION_ROLE_PRIVILEDGES-RESULT: ', result);
      if (result.success === true) {
        yield put({
          type: actions.FETCHED_INTEGRATION_ROLES_PRIVILEGES,
          payload: result.data,
        });
      } else {
        yield put({
          type: actions.FETCHING_INTEGRATION_ROLES_PRIVILEGES_FAILED,
          payload: result.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCHING_INTEGRATION_ROLES_PRIVILEGES_FAILED,
        payload: error,
      });
    }
  });
}
export function* updateAccessControl() {
  yield takeEvery('UPDATE_INTEGRATION_ROLES_PRIVILEGES', function* (payload) {
    try {
      yield put({
        type: actions.UPDATING_INTEGRATION_ROLES_PRIVILEGES,
      });
      const apiResult = yield updateAccessControlData(payload.payload);
      const result = apiResult.data;
      console.log('UPDATE_INTEGRATION_ROLES_PRIVILEGES-RESULT: ', apiResult);
      if (result.success === true) {
        yield put({
          type: actions.UPDATED_INTEGRATION_ROLES_PRIVILEGES,
          payload: result.data,
          message: 'Access Control successfully updated',
        });
      }
    } catch (error) {
      yield put({
        type: actions.UPDATING_INTEGRATION_ROLES_PRIVILEGES_FAILED,
        payload: error,
        message: 'Updating Access Control failed',
      });
    }
  });
}
export function* addAccessControl() {
  yield takeEvery('ADD_INTEGRATION_ROLES_PRIVILEGE', function* (payload) {
    try {
      yield put({
        type: actions.ADDING_INTEGRATION_ROLES_PRIVILEGE,
      });
      const apiResult = yield addAccessControlData(payload.payload);
      const result = apiResult.data;
      console.log('ADD_INTEGRATION_ROLES_PRIVILEGE-RESULT: ', apiResult);
      if (result.success === true) {
        yield put({
          type: actions.ADDED_INTEGRATION_ROLES_PRIVILEGE,
          payload: result.data,
          message: 'Access Control successfully Added',
        });
      }
    } catch (error) {
      yield put({
        type: actions.ADDING_INTEGRATION_ROLES_PRIVILEGE_FAILED,
        payload: error,
        message: 'Adding Access Control failed',
      });
    }
  });
}
export function* fetchIntegratorsAdminAccess() {
  yield takeEvery('FETCH_INTEGRATORS_ADMIN_ACCESS', function* (payload) {
    try {
      yield put({
        type: actions.FETCHING_INTEGRATORS_ADMIN_ACCESS,
      });
      const apiResult = yield fetchIntegratorsAdminAccessData(payload.payload);
      const result = apiResult.data;
      console.log('FETCH_INTEGRATORS_ADMIN_ACCESS-RESULT: ', apiResult);
      if (result.success === true) {
        yield put({
          type: actions.FETCHED_INTEGRATORS_ADMIN_ACCESS,
          payload: result.data,
          message: 'Access Control successfully Added',
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCHING_INTEGRATORS_ADMIN_ACCESS_FAILED,
        payload: error,
        message: 'Adding Access Control failed',
      });
    }
  });
}
export function* fetchAwsDocuments() {
  yield takeEvery('FETCH_AWS_DOCUMENTS', function* (payload) {
    try {
      yield put({
        type: actions.FETCHING_AWS_DOCUMENTS,
      });
      // console.log('payload.payload', payload.payload);
      const apiResult = yield fetchingAwsDocuments(payload.payload);
      // console.log('FETCH_AWS_DOCUMENTS-RESULT: ', apiResult);
      const result = apiResult.data;
      if (result.success === true) {
        yield put({
          type: actions.FETCHED_AWS_DOCUMENTS,
          payload: result.fileBase64,
          message: 'Access Control successfully Added',
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCHING_AWS_DOCUMENTS_FAILED,
        payload: error,
        message: 'Fetching Aws Documents failed',
      });
    }
  });
}
//SEARCH WALLET DETAILS
function searchingWalletDetails(data) {
  return postWithToken(`corp_wallet/wallet/search/`, data);
}
// FETCH AWS DOCUMENTS
function fetchingAwsDocuments(url) {
  return get(`downloadlinks/fetch_aws_documents/${url}`);
}
// FUND SOURCE
function getFundSourceData(data) {
  return get(`walletgroups/`, data, data.page, null, null);
}
// PREDICTIVE BALANCE
function getPredictiveBalanceData(data) {
  return get(`bankaccounts/`, data, data.page, null, null);
}
function getPredictiveBalanceBankAccount(data) {
  return postWithToken(`bankaccounts/report/predictive_balance/`, data);
}
function getPast90Days(data) {
  return postWithToken(`predictivebalstats/report/prev_90days/`, data);
}
function getBankAccts() {
  return get(`bankaccounts/`);
}
function fundSourceAdd(data) {
  return postWithToken(`walletgroups/`, data);
}
function fundSourceEdit(data) {
  return PUT(`walletgroups/${data.Id}`, data);
}
function fundSourceDelete(data) {
  return PUT(`walletgroups/delete/${data.Id}`, data);
}
function fundSourceDeactivate(data) {
  return PUT(`walletgroups/change_status/${data.Id}`, data);
}
// TRX QUEUE
function getTrxQueueMerchant(data) {
  return postWithToken(`queues/get_merchants_v2/`, data);
}
function getTrxQueues(data) {
  return postWithToken(`queues/get_all_queues/`, data);
}
function getTrxQueueWalletIds(id) {
  return get(`queues/get_wallet_ids/${id}`);
}
function filteringTrxQueue(data) {
  return postWithToken(`queues/filter/`, data);
}

// DOWNLOAD TRANSACTION REPORT
function downloadTrxReport(data) {
  return postWithToken(`downloadlinks/download/load_withdraw/kotlin/`, data);
}
// DOWNLOAD ADMIN REPORT
function downloadingAdminReport(data) {
  return postWithToken(`downloadlinks/download/admin_report/kotlin/`, data);
}
function downloadingAdminWithheldReport(data) {
  return postWithToken(`downloadlinks/download/admin_report/withheld/kotlin/`, data);
}
function downloadReconReport(data) {
  return postWithToken(`downloadlinks/download/recon/kotlin/`, data);
}
function getDownloadLists() {
  return get(`downloadlinks/fetch_download_list/id`);
}
function getPrevDownloads() {
  return get(`downloadlinks/fetch_previous_downloads/id`);
}

// AUTH MANAGEMENT
function fetchAuthManagementRoleRequest() {
  return get(`roles/fetch_all_roles/ui`);
}
function fetchIntegrationRolesData() {
  return get(`integrationroles/ui/get_integ_key/wallet_id/`);
}
function fetchIntegrationRolesPrivilegesData(data) {
  //console.log('integBody',data);
  return post(`integrationroles/ui/find_integ_key/access/`,data);
}
function fetchCoreModuleRequest() {
  // return get(`core-modules/system/modules/new/${id}`);
  return get(`core-modules/system/modules/new`);
}
function fetchRolePermissionRequest(id) { // here
  return get(`roles/role_authorization/${id}`);
}
function saveAuthManagementReq(id, data) {
  // console.log('SAVE_AUTH_DATA', data);
  // return get(`core-modules/system/modules/new/${id}`);
  return post(`roles/${id}/authorization/update_roles_auth`, data);
}
function addAuthRoleReq(data) {
  // console.log('AUTH ROLE DATA', data);
  return postWithToken(`lookups/save_role`, data);
}
function fetchStatusRequest() {
  // add endpoint for auth management save
  return get(`lookups/fetch/status/`);
}
function fetchMerchantUsersRequest(merchantIdentity, store, payload) {
  const newData = {
    mid: payload && payload.mid ? payload.mid : merchantIdentity.merchantId,
    role: merchantIdentity.role.role.label,
    store: store.length > 0 ? store[0].storeId : '',
    authId: merchantIdentity.Id,
  };
  return get(`users/get_all/new/`, newData, payload.page, null, null);
}
// FETCH TOTAL TRANSACTIONS URL
function fetchTotalTransactionsData(data) {
  // console.log("TOTAL_TRANSACTIONS_DATA", data);
  return postWithToken(`centralizedrequestv2/get_total_transactions/`, data);
}

// FETCH ALL TRANSACTIONS URL
function fetchAllTransactionsData(data) {
  // console.log("ALL_TRANSACTIONS_DATA", data);
  return postWithToken(`journalentries/getAll/transactions/`, data);
}
// FETCH WALLET DETAILS
function fetchWalletDetailsData(payload) {
  console.log('WalletDetaislPayloadChecker',payload);
  // return get(`company/company_code/${payload}`);
  return get(`company/corp/company_code/${payload}`);
}
// FETCH MERCHANTS URL
function fetchMerchantsData() {
  return get(`company/get_all_merchant`);
}
//FETCH WITHHELD TRANSACTIONS
function fetchWithheldTransactionsData(payload) {
  console.log('fetchWithheldTransactionsData', payload);
  if (payload.page) {
    return get(`withhelds/get_transactions/${payload.walletId}`, null, payload.page);
  } else {
    return get(`withhelds/get_transactions/${payload}`);
  }

}
function searchWithheldTransactionsData(payload) {
  if(payload && payload.fromDate && payload.toDate && !payload.tRef){
    console.log('SearchWithheldTransactionsData', payload);
    const value ={
      fromDate:payload.fromDate,
      toDate:payload.toDate
    }
    return post(`withhelds/get_transactions/date_range/${payload.walletId}`,value);
  }else if (payload && payload.tRef && !payload.toDate && !payload.fromDate){
    console.log('transactionNumber',payload);
    return post(`withhelds/get_transactions/transaction_ref/${payload.walletId}`,payload);
  }else{
    return post(`withhelds/get_transactions/transaction_ref/Date_Range/${payload.walletId}`,payload);
  }

}

// FETCH CORP WALLETS
function fetchCorpWalletsByMidData(id) {
  // console.log("CORP_WALLETS_DATA", encodeURIComponent(id));
  return get(`centralizedrequestv2/find_wallet/${encodeURIComponent(id)}`);
}

// FETCH TRANSACTIONS COUNT
function fetchTransactionsCountData(data) {
  // console.log("TRANSACTIONS_COUNT-1", data);
  return postWithToken(`bankaccounts/getCountById/`, data);
}

// FETCH ALL TRANSACTIONS COUNT
function fetchAllTransactionsCountData(data) {
  // console.log("ALL_TRANSACTIONS_COUNT-2", data);
  return postWithToken(`bankaccounts/getDashboardCount/`, data);
}

// FETCH ALL TRANSACTION DETAILS
function fetchAllTransactionDetailsData(data) {
  // console.log("TRANX_DATA", data);
  return postWithToken(`journalentries/transactions/all/`, data);
}

//UPDATE THRESHOLD
function updateThresholdData(data) {
  console.log("Threshold Data", data);
  return PUT(`/corp_wallet/threshold/${data.Id}`, data);
}
//FETCH LOOKUPS CURRENCY
function fetchLookupsCurrencyData() {
  console.log("CURRENY");
  const groupCurrency = { group: "CURRENCY" }
  return get(`lookups/`, groupCurrency);
}
//FETCH WALLET GROUPS
function fetchWalletGroupsData() {
  console.log("WALLETGROUPS");
  const pagination = { page: 1, limit: 25 }
  return get(`walletgroups/`, null, pagination);
  // return get(`walletgroups/`);
}
//ADD CORP WALLET
function addCorpWalletData(data) {
  console.log("addCorpWalletData", data);
  return postWithToken(`corp_wallet/`, data);
}
// add system params
function addCorpWalletSystemParamsData(data) {

  const dataValues = {
    key: data.walletId,
    value: false
  }
  console.log("addCorpWalletDataSys", dataValues);
  return postWithToken(`systemsettings/`, dataValues);
}
//FETCH DAILY BALANCE
function fetchDailyBalanceData(data) {
  console.log("fetchDailyBalanceData", data);
  return postWithToken(`dailywalletbalances/daily/wallet_balance/`, data);
}
// FETCH ALL TRANSACTION DETAILS
function fetchBankDetailsByTranxIdData(id) {
  // console.log("TRANX_ID", id);
  return get(`journalentries/${id}`);
}
function postCreditTransactionData(data) {
  console.log("postCreditTransaction", data);
  return postWithToken(`corp_wallet/ui/credit/new/`, data);
}
function postDebitTransactionData(data) {
  console.log("postDeditTransaction", data);
  return postWithToken(`corp_wallet/ui/debit_pre/new/`, data);
}
function postTransferTransactionData(data) {
  console.log("postTransferTransaction", data);
  return postWithToken(`corp_wallet/ui/transfer_pre/new/`, data);
}
function fetchIntegratorsAdminAccessData(payload) {
  // console.log("postTransferTransaction", data);
   return postWithToken(`authenticate/getSelect/`,payload);
}
function postSettleTransactionData(data) {

  if (data.transactionType === "TRANSFER") {
    delete data.transactionType;
    console.log("postSettleTransferTransaction", data);
    return postWithToken(`corp_wallet/ui/transfer_settle/`, data);
  } else {
    delete data.transactionType;
    console.log("postSettleDebitTransaction", data);
    return postWithToken(`corp_wallet/ui/debit_settle/`, data);
  }

}
function postCancelTransactionData(data) {

  if (data.transactionType === "TRANSFER") {
    delete data.transactionType;
    console.log("postSettleTransferTransaction", data);
    return postWithToken(`corp_wallet/ui/transfer_cancel/`, data);
  } else {
    delete data.transactionType;
    console.log("postSettleDebitTransaction", data);
    return postWithToken(`corp_wallet/ui/debit_cancel/`, data);
  }

}
function changeWalletStatusData(data) {
  console.log('ChangeWalletdata', data);
  return PUT(`corp_wallet/set_status/${data.Id}`, data);
}

// FETCH SEARCH TRANSACTION DETAILS
function fetchSearchTransactionDetailsData(data) {
  // console.log("TRANX_DATA", data);
  return postWithToken(`journalentries/search/transactions`, data);
}

// FETCH BANK ACCOUNT BY ACCT NO DATA
function fetchBankAccountByAcctNoData(id) {
  // console.log("TRANX_DATA", data);
  return get(`bankaccounts/bank_account_no/${id}`);
}

function bankAccountAddData(data) {
  return postWithToken(`bankaccounts/`, data);
}

function bankAccountEditData(data) {
  return PUT(`bankaccounts/${data.Id}`, data);
}

function bankAccountDeleteData(data) {
  return PUT(`bankaccounts/delete/${data.Id}`, data);
}

function bankAccountDeactivateData(data) {
  return PUT(`bankaccounts/change_status/${data.Id}`, data);
}
function updateAccessControlData(data) {
  //console.log('updateAccessControlData',data);
  return PUT(`integrationroles/ui/${data.Id}`, data);
}
function addAccessControlData(data) {
  //console.log('addAccessControlData',data);
  return post(`integrationroles/ui/saveIntegWalletAndRole/accessControl/`, data);
}

//FETCH LOOKUPS CURRENCY
function fetchLookupsBankData() {
  console.log("BANK_LOOKUPS");
  const group = { group: "BANKS" }
  return get(`lookups/`, group);
}

// FETCH SEARCH MERCHANT TRANSACTION DETAILS
function fetchSearchMerchantTransactionDetailsData(data) {
  // console.log("TRANX_DATA", data);
  return postWithToken(`journalentries/search/merchant/transactions`, data);
}

export default function* rootSaga() {
  yield all([
    fork(saveAuthManagement),
    fork(fetchStatus),
    fork(addAuthRole),
    fork(fetchRolePermission),
    fork(fetchMerchants),
    fork(fetchTotalTransactions),
    fork(fetchAllTransactions),
    fork(fetchWalletDetails),
    fork(fetchWithheldTransactions),
    fork(fetchCorpWalletsByMid),
    fork(fetchTransactionsCount),
    fork(fetchAllTransactionsCount),
    fork(fetchAllTransactionDetails),
    fork(updateThreshold),
    fork(fetchWalletGroups),
    fork(fetchLookupsCurrency),
    fork(addCorpWallet),
    fork(fetchDailyBalance),
    fork(fetchBankDetailsByTranxId),
    fork(postCreditTransaction),
    fork(postDebitTransaction),
    fork(postTransferTransaction),
    fork(postSettleTransaction),
    fork(postCancelTransaction),
    fork(fetchTrxQueueMerchants),
    fork(fetchTrxQueue),
    fork(fetchTrxQueueWalletId),
    fork(trxQueueFilter),
    fork(fetchSearchTransactionDetails),
    fork(downloadWithdrawLoadReport),
    fork(downloadReconciliationReport),
    fork(fetchDownloadList),
    fork(fetchPrevDownloads),
    fork(fetchFundSource),
    fork(fetchPredictiveBalance),
    fork(fetchPredictiveBalanceBankAccount),
    fork(fetchPast90Days),
    fork(fetchBankAccts),
    fork(changeWalletStatus),
    fork(addingFundSource),
    fork(editingFundSource),
    fork(deletingFundSource),
    fork(deactivatingFundSource),
    fork(fetchBankAccountByAcctNo),
    fork(deactivatingBankAccount),
    fork(deletingBankAccount),
    fork(addingBankAccount),
    fork(editingBankAccount),
    fork(fetchLookupsBank),
    fork(downloadAdminReport),
    fork(fetchSearchMerchantTransactionDetails),
    fork(walletDetailsSearch),
    fork(searchWithheldTransactions),
    fork(fetchIntegrationRoles),
    fork(fetchIntegrationRolesPrivileges),
    fork(updateAccessControl),
    fork(addAccessControl),
    fork(fetchIntegratorsAdminAccess),
    fork(fetchAwsDocuments),
  ]);
}
